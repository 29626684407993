/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('[data-toggle="tooltip"]').tooltip();

        jQuery(function () {
          jQuery('#watsapp').floatingWhatsApp({
            phone: '351913000135',
            popupMessage: 'Olá, como podemos ajudar?',
            position: 'right',
            showPopup: true
          });
        });

       

        function fixedHeader() {
          if ($(window).width() <= 992) {

            $('#menu').addClass('fixed');
          } else {

            $('#menu').removeClass('fixed');
          }
        }

        fixedHeader();

        $(window).scroll(function () {

          if ($(window).width() > 992) {
            if ($(window).scrollTop() >= 30) {
              $('header').addClass('fixed');
              $('#menu').addClass('fixed');
            }
            else {
              $('header').removeClass('fixed');
              $('#menu').removeClass('fixed');
            }
          }
        });

        $('.show-menu').click(function () {
          var cardH = $('#myNav .menu-list').height() / 2;
          $('#myNav .menu-list').css('top', '50vh').css('top', '-=' + cardH + 'px');

          if ($(window).width() > 575) {
            $('#myNav').css('width', '100vw');
          }
          else {
            $('#myNav').css('width', '100vw');
            $(document.body).addClass('overflow-hidden');
          }

          $('.close-block').css('display', 'flex').hide().fadeIn();
        });

        $('.search-icon').click(function () {
          console.log("open-searhc");
          $(".dgwt-wcas-search-wrapp").toggleClass( "open" );
        });

        $( '.toggle-filter' ).on(
          'click',
          function(){
            if ($( this ).find( '.icon.filter' ).length == 1) {
              $( '.toggle-filter .icon.filter' ).removeClass( 'filter' ).addClass( 'close-filter' );
              $( this ).addClass( 'active' );
            } else {
              $( '.toggle-filter .close-filter' ).removeClass( 'close-filter' ).addClass( 'filter' );
              $( this ).removeClass( 'active' );
            }
            $( '.expanded-filter' ).slideToggle( 600, "swing" );
            if ($( '.woocommerce-widgets-wrapper' ).hasClass( 'active' )) {
              $( '.woocommerce-widgets-wrapper' ).removeClass( 'active' );
            } else {
              $( '.woocommerce-widgets-wrapper' ).addClass( 'active' );
            }
          }
        );


        

        $('#closeNav').click(function () {
          $('#myNav').css('width', '0');
          $('.close-block').fadeOut(300);
          $(document.body).removeClass('overflow-hidden');
        });


        $('#method').on('change', function (e) {
          var optionSelected = $("option:selected", this);
          var valueSelected = this.value;
          console.log (valueSelected);

          if( valueSelected =="Ponto recolha"){
            $("#point").fadeIn();
          }else{
            $("#point").fadeOut();
          }

        });



  // ================================================================= //
  //	 		   *COOKIES                                                  //
  //	================================================================= //

  var cookieDisclaimer = $('#cookie-notice');

  function checkStartingCookiePrompt()
  {
    if(readCookie('session_cookie') === null)
    {
      cookieDisclaimer.show();
    }
    else
    {
      cookieDisclaimer.hide();
    }
  }

  checkStartingCookiePrompt();

  $('#close-cookie').click(function()
  {
    createCookie('session_cookie', '1', 365);
    cookieDisclaimer.hide();
    return false;
  })

  function createCookie(name, value, days) {
    var expires;

    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
  }

  function readCookie(name) {
      var nameEQ = encodeURIComponent(name) + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) === ' ')
              c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) === 0)
              return decodeURIComponent(c.substring(nameEQ.length, c.length));
      }
      return null;
  }


  var dropdown = jQuery('.bag');
	var subelement = jQuery('.widget_cart').css({display:'none', opacity:0});

  	dropdown.hover(
  	function(){ subelement.css({display:'block'}).stop().animate({opacity:1}); },
  	function(){ subelement.stop().animate({opacity:0}, function(){ subelement.css({display:'none'}); }); }
  	);

    subelement.hover(
    function(){ subelement.css({display:'block'}).stop().animate({opacity:1}); },
    function(){ subelement.stop().animate({opacity:0}, function(){ subelement.css({display:'none'}); }); }
    );



    /* product categories*/

    /**
     * WooCommerce Product Category Accordion jQuery Menu
     * @link https://wpbeaches.com/woocommerce-accordion-style-expand-collapse-product-category-menu/
     */

     if ($('ul.product-categories').length > 0) {

      // Set variables
      // pC = Parent Category
      // fpC = First Parent Category
      // cC = Current Category
      // cCp = Currents Category's Parent

      var
      pC = $('.product-categories li.cat-parent'),
      fpC = $('.product-categories li.cat-parent:first-child'), // Start this one open
      cC = $('.product-categories li.current-cat'),
      cCp = $('.product-categories li.current-cat-parent');

      pC.prepend('<span class="toggle"><i class="far fa-minus-square fa-plus-square"></i></span>');
      pC.parent('ul').addClass('has-toggle'); pC.children('ul').hide();

      if (pC.hasClass("current-cat-parent")) {
          cCp.addClass('open'); cCp.children('ul').show(); cCp.children().children('i.far').removeClass('fa-plus-square');
      }
      else if (pC.hasClass("current-cat")) {
          cC.addClass('open'); cC.children('ul').show(); cC.children().children('i.far').removeClass('fa-plus-square');
      }
      else {
          fpC.addClass('open'); fpC.children('ul').show(); fpC.children().children('i.far').removeClass('fa-plus-square');
      }

      $('.has-toggle span.toggle').on('click', function() {
          $t = $(this);
          if ($t.parent().hasClass("open")){
              $t.parent().removeClass('open'); $t.parent().children('ul').slideUp(); $t.children('i.far').addClass('fa-plus-square');
          }
          else {
              $t.parent().parent().find('ul.children').slideUp();
              $t.parent().parent().find('li.cat-parent').removeClass('open');
              $t.parent().parent().find('li.cat-parent').children().children('i.far').addClass('fa-plus-square');

              $t.parent().addClass('open');
              $t.parent().children('ul').slideDown();
              $t.children('i.far').removeClass('fa-plus-square');
          }

      });


      // Link the count number
      $('.count').css('cursor', 'pointer');
      $('.count').on('click', function(event) {
          $(this).prev('a')[0].click();
      });

  }
  





      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('#menu').removeClass('dark');
        $('#menu').addClass('light');

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'envia_o_teu_filme': {
      init: function() {
        // JavaScript to be fired on the about us page

        $("#insta-check").click(function() {
          var checked_status = this.checked;
          if (checked_status == true) {
            $("#instagram-name").show();
          } else {
            $("#instagram-name").hide();
          }
        });
      }
    },
    'showcase': {
      init: function() {




// filter functions
var filterFns = {
  // show if number is greater than 50
  numberGreaterThan50: function() {
    var number = $(this).find('.number').text();
    return parseInt( number, 10 ) > 50;
  },
  // show if name ends with -ium
  ium: function() {
    var name = $(this).find('.name').text();
    return name.match( /ium$/ );
  }
};

function getHashFilter() {
  // get filter=filterName
  var matches = location.hash.match( /filter=([^&]+)/i );
  var hashFilter = matches && matches[1];
  return hashFilter && decodeURIComponent( hashFilter );
}

// init Isotope
var $grid = $('.grid');

// bind filter button click
var $filterButtonGroup = $('.filter-button-group');
$filterButtonGroup.on( 'click', 'button', function() {
  var filterAttr = $( this ).attr('data-filter');
  // set filter in hash
  location.hash = 'filter=' + encodeURIComponent( filterAttr );
});

var isIsotopeInit = false;

function onHashchange() {
  var hashFilter = getHashFilter();
  if ( !hashFilter && isIsotopeInit ) {
    return;
  }
  isIsotopeInit = true;
  // filter isotope
  $grid.isotope({
    itemSelector: '.element-item',
    layoutMode: 'fitRows',
    // use filterFns
    filter: filterFns[ hashFilter ] || hashFilter
  });
  // set selected class on button
  if ( hashFilter ) {
    $filterButtonGroup.find('.is-checked').removeClass('is-checked');
    $filterButtonGroup.find('[data-filter="' + hashFilter + '"]').addClass('is-checked');
  }
}

$(window).on( 'hashchange', onHashchange );

// trigger event handler to init Isotope
onHashchange();









      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
